import $ from 'jquery-slim';

let $html;
let offset;

class CollapseScrollTo
{
    constructor($element)
    {
        this.$element = $element;

        this.isScrolling = this.$element[0].hasAttribute('scroll-to');

        this.$element.on('shown.bs.collapse', this.scrollTo.bind(this));
    }

    scrollTo(e)
    {
        e.stopImmediatePropagation();

        if (!this.isScrolling) return;

        let offsetTop = offset.getBoundingClientRect().top;
        let elementTop = this.$element[0].getBoundingClientRect().top;
        $html.scrollTo(elementTop - offsetTop, 500);
    }
}

class OptCollapseScrollTo
{
    constructor()
    {
        $(document).ready(this.init.bind(this));
    }

    init()
    {
        $html = $('html, body');
        offset = $('#scroll-offset')[0];


        let $elements = $('.collapse');

        this.collapse = [];

        for (let i = 0; i < $elements.length; i++) this.collapse.push(new CollapseScrollTo($elements.eq(i)));
    }

}

const collapseScrollTo = new OptCollapseScrollTo();






